<template>
    <div class="newReply-wrapper">
        <div class="content">
            <el-breadcrumb separator=">">
                <el-breadcrumb-item :to="{ path: '/payAttentionToReplyList', query: {appid: appid, nickName: nickName, headImg: headImg } }">关注回复</el-breadcrumb-item>
                <el-breadcrumb-item>新增回复</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="keywords-wrap">
                <div class="keywords-box">
                    <div class="keywords-item">
                        <div class="keywords-name">规则名称</div>

                        <div class="keywords-inp">
                            <el-input type="text" v-model="ruleName" placeholder="请输入规则名称" maxlength="15"
                             show-word-limit clearable />
                        </div>

                    </div>
                </div>

                <div class="reply-content">
                    <div class="reply-name">回复内容</div>

                    <div class="reply-right">
                        <div class="choose-box">
                            <div class="choose-item reply-item" v-for="(item, index) in contData" :key="index"
                             @click="chooseCont(index)">
                                <div class="choose-icon" v-if="contIndex !== index">
                                    <img src="../../assets/ellipse.png" />
                                </div>
                                <div class="choose-icon" v-if="contIndex == index">
                                    <img src="../../assets/ellipse-hover.png" />
                                </div>
                                <div class="choose-text">{{item.texts}}</div>
                            </div>
                        </div>

                        <!-- 文本 -->
                        <div class="tab-cont" v-show="contIndex == 0">
                            <div class="nvarchar-wrap">
                                <div class="nvarchar-top">
                                    <div class="-top-title">插入类型：</div>

                                    <div class="insert-type">
                                        <div class="nickname-item" @click.stop.prevent="nickname" v-if="false">
                                            <div class="nickname-icon"></div>
                                            <div class="nickname-text">粉丝昵称</div>
                                        </div>
                                        <div class="nickname-item" @click="hyperlink">
                                            <div class="nickname-icon hyperlink-icon"></div>
                                            <div class="nickname-text">超链接</div>
                                        </div>
                                        <div class="nickname-item" @click="hyperApplet">
                                            <div class="nickname-icon link-icon"></div>
                                            <div class="nickname-text">小程序链接</div>
                                        </div>
                                        <div class="nickname-item" @click="hyperKey">
                                            <div class="nickname-icon key-icon"></div>
                                            <div class="nickname-text">回复关键词</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="reply-text">
                                    <vue-ueditor-wrap v-model="ueText" @ready="ready" :config="editorConfig"></vue-ueditor-wrap>
                                </div>

                                <div class="reply-bot" v-if="false">
                                    <el-popover
                                     placement="bottom-start"
                                     :width="396"
                                     trigger="click">
                                        <template #reference>
                                            <div class="face-btn">
                                                <img src="../../assets/vector-icon.png" />
                                            </div>
                                        </template>

                                        <div class="face-list">
                                            <div class="face-item" v-for="(item, index) in EmotionList" :key="index" @click="ClickEmoticon(item)">
                                                <img :src="'https://c-admin-vanilla-1251249694.cos.ap-guangzhou.myqcloud.com/icon/' + item + '.png'" :alt="item.alt" />
                                            </div>
                                        </div>
                                    </el-popover>

                                    <div class="words-text" v-if="false">（{{tatVal}}/300），按下Enter键换行</div>
                                </div>

                            </div>

                        </div>

                        <!-- 图片 -->
                        <div class="tab-cont" v-show="contIndex == 1">
                            <div class="picture-item">
                                <div class="picture-name">添加图片：</div>
                                <div class="picture-btn" v-if="imageUrl == ''" @click="selectImage">
                                    <div class="el-icon-btn">
                                        <div class="el-icon-plus"></div>
                                    </div>
                                    <div class="picture-text">添加图片</div>
                                </div>

                                <div class="reselect-item" v-else>
                                    <div class="picture-btn">
                                        <img :src="imageUrl" />
                                    </div>

                                    <div class="reselect-btn" @click="selectImage">重新选择</div>
                                </div>

                            </div>
                        </div>

                        <!-- 小程序卡片 -->
                        <div class="tab-cont" v-show="contIndex == 2">
                            <div class="appletCard-box">
                                <div class="appletCard-inpItem">
                                    <div class="inpItem-name">卡片标题</div>
                                    <div class="inpItem">
                                        <el-input type="text" v-model="titleCard" placeholder="请输入卡片标题" clearable />
                                    </div>
                                </div>
                                
                                <div class="appletCard-inpItem">
                                    <div class="inpItem-name">小程序AppID</div>

                                    <div class="inpItem">
                                        <el-select v-model="cardAppid"
                                         filterable allow-create default-first-option
                                         placeholder="请输入小程序AppID或绑定后快捷选择"
                                         @change="selectMarket">
                                            <el-option v-for="item in wxOptions" :key="item.nickname"
                                             :label="item.nickname" :value="item.appid">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <a href="https://docs.qq.com/doc/DWEpwb1hTWW5VcWta" target="_blank">如何获取?</a>
                                </div>
                                <div class="appletCard-inpItem">
                                    <div class="inpItem-name">小程序路径</div>
                                    
                                    <div class="inpItem">
                                        <el-input type="text" v-model="appletsPath" placeholder="请输入跳转小程序页面 示例：pages/index/index" clearable />
                                    </div>

                                    <a href="https://docs.qq.com/doc/DYnZma096YW52dEtv" target="_blank">如何获取?</a>
                                </div>

                                <div class="appletCard-inpItem" style="align-items: flex-start;">
                                    <div class="inpItem-name">封面图</div>

                                    <div class="picture-btn" v-if="thumbMediaUrl == ''" @click="selectImage">
                                        <div class="el-icon-btn">
                                            <div class="el-icon-plus"></div>
                                        </div>
                                        <div class="picture-text">添加图片</div>
                                    </div>
                                    <div class="reselect-item" v-else>
                                        <div class="picture-btn">
                                            <img :src="thumbMediaUrl" />
                                        </div>

                                        <div class="reselect-btn" @click="selectImage">重新选择</div>
                                    </div>

                                    <div class="account-img">
                                        <!-- :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :http-request="positiveCard" -->
                                        <el-upload class="avatar-uploader" :action="action"
                                        :show-file-list="false"
                                        
                                        
                                        
                                        :limit="1">
                                            <img v-if="cardImageUrl" :src="cardImageUrl" class="avatar" />
                                            <el-icon v-else class="avatar-uploader-icon"></el-icon>

                                            <div slot="file" slot-scope="{file}">
                                                <img class="el-upload-list__item-thumbnail"
                                                :src="cardImageUrl" alt=""/>
                                                <span class="el-upload-list__item-actions" v-if="cardImageUrl">
                                                    <span class="el-upload-list__item-preview"
                                                    @click="handlePictureCardPreview(file)">
                                                        <i class="el-icon-zoom-in"></i>
                                                    </span>
                                                    <span v-if="!disabled" class="el-upload-list__item-delete"
                                                    @click="handleDownload(file)">
                                                        <i class="el-icon-download"></i>
                                                    </span>
                                                    <span v-if="!disabled" class="el-upload-list__item-delete"
                                                    @click="handleRemove(file)">
                                                        <i class="el-icon-delete"></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </el-upload>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!-- 图文 div-contenteditable -->
                        <div class="tab-cont" v-show="contIndex == 3">
                            <div class="material-box" v-if="newsUrl !== ''">
                                <div class="reselect-item">
                                    <div class="material-item" style="margin: 0;">
                                        <div class="material-img">
                                            <img :src="newsUrl" />
                                        </div>
                                        <div class="material-text">{{newsTitle}}</div>
                                    </div>

                                    <div class="reselect-btn" @click="showMat">重新选择</div>
                                </div>
                            </div>
                            
                            <div class="image-text-btn" v-if="newsUrl == ''" @click="showMat">
                                <div class="el-icon-plus"></div>
                                <div class="add-text">素材库导入</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <!-- <div class="new-b-wrap">
                <div class="new-btn">新建回复</div>
                <div class="new-text">*最多同时发送3条回复，同时发送多条消息可能导致消息发送不成功，建议仅发送一条</div>
            </div> -->

            
            <div class="btnsItem">
                <el-button type="danger" :disabled="disabled" @click="save">保存</el-button>
                
                <el-button @click="cancel">取消</el-button>
            </div>
            
        </div>

        <!-- 添加链接 -->
        <el-dialog title="插入链接" :visible.sync="showLink" width="35%" center>
            <div class="link-wrap">
                <el-alert
                 title="注：如需添加超链接，当前公众号必须已认证，且已开通微信支付功能，否则添加后将不会生效。"
                 type="warning" show-icon :closable="false">
                </el-alert>

                <div class="link-inpItem">
                    <div class="link-inpText">链接文本</div>
                    <div class="link-inp">
                        <el-input type="text" v-model="linkText" placeholder="请输入链接显示文本" clearable />
                    </div>
                </div>
                <div class="link-inpItem">
                    <div class="link-inpText">链接地址</div>
                    <div class="link-inp">
                        <el-input type="text" v-model="linkAddress" placeholder="请输入要插入的链接地址" clearable />
                    </div>
                </div>

                <div class="btns-item">
                    <el-button type="primary" @click="confirmLink">确 定</el-button>
                    <el-button @click="showLink = false">取 消</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 添加小程序链接 -->
        <el-dialog title="插入小程序链接" :visible.sync="showApplet" width="35%" center>
            <div class="link-wrap applet">
                <el-alert title="需要在公众号后台关联此小程序，否则无法发送成功，"
                 type="warning" show-icon :closable="false">
                    <a target="_blank" href="https://static.sichuanyunzhan.com/yzlm/yzlm_bindmini.html"
                     style="text-decoration: none;color: rgb(78, 115, 236);">点此查看如何关联。</a>
                </el-alert>

                <div class="link-inpItem">
                    <div class="link-inpText">小程序AppID</div>
                    
                    <div class="link-inp">
                        <el-select v-model="pagesAppid"
                         filterable allow-create default-first-option
                         placeholder="请输入小程序AppID或绑定后快捷选择" @change="selectAppid">
                            <el-option v-for="item in wxOptions" :key="item.nickname"
                             :label="item.nickname" :value="item.appid"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="link-inpItem">
                    <div class="link-inpText">显示文字内容</div>
                    <div class="link-inp">
                        <el-input type="text" v-model="dispText" placeholder="请输入链接显示文本" clearable />
                    </div>
                </div>
                <div class="link-inpItem">
                    <div class="link-inpText">小程序页面地址</div>
                    <div class="link-inp">
                        <el-input type="text" v-model="pagesText" placeholder="示例：pages/index/index" clearable />
                    </div>
                </div>

                <div class="btns-item">
                    <el-button type="primary" @click="confirmApplet">确 定</el-button>
                    <el-button @click="showApplet = false">取 消</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 添加添加关键词回复 -->
        <el-dialog title="插入关键词回复" :visible.sync="showKey" width="35%" center>
            <div class="link-wrap applet">
                <el-alert
                 title="如果您设置了关键词自动回复，公众号粉丝点击「回复关键词」后会让粉丝自动回复对应的文本，即可触发您设置的关键词"
                 type="warning" show-icon :closable="false">
                </el-alert>

                <div class="link-inpItem">
                    <div class="link-inpText">显示文字内容</div>
                    <div class="link-inp">
                        <el-input type="text" v-model="KeyText" placeholder="请输入显示文字内容,非必填" clearable />
                    </div>
                </div>
                <div class="link-inpItem">
                    <div class="link-inpText">回复关键词</div>
                    <div class="link-inp">
                        <el-input type="text" v-model="KeyName" placeholder="请输入回复关键词" clearable />
                    </div>
                </div>

                <div class="btns-item">
                    <el-button type="primary" @click="confirmKey">确 定</el-button>
                    <el-button @click="showKey = false">取 消</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog title="导入素材库文章" :visible.sync="showMaterial" width="50%">
             <!-- :before-close="handleClose" -->
            <div class="material-library">
                <div class="material-box">
                    <div class="material-item newsItem" v-for="(item, index) in newsItemData" :key="index"
                     @click="chooseLibrary(index, item.thumb_media_id, item.thumb_url, item.title)">
                        <div class="material-img" :class="{activeImg:newsIndex == index}">
                            <img :src="item.thumb_url" />
                        </div>
                        <div class="material-text">{{item.title}}</div>
                    </div>

                </div>

                <div class="paging-item">
                    <el-pagination background @current-change="newsHandleCurrentChange"
                     :pageSize="newsPageSize" layout="total, prev, pager, next" :total="newsTotal">
                    </el-pagination>
                </div>
                
                <div class="material-btn">
                    <el-button type="primary" @click="confirmNews">确 定</el-button>
                    <el-button @click="showMaterial = false">取 消</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog title="选择图片" :visible.sync="showSelectImage" width="70%">
            <div class="select-imageWrap">
                <div class="upload-item">
                    <el-upload class="upload-demo" ref="upload" :action="action" :clearFiles="clearFiles"
                     :on-preview="handlePreview" :on-remove="handleRemove" :on-success="onSuccess"
                     :before-remove="beforeRemove" :data="param" :show-file-list="false"
                     :on-exceed="handleExceed" :before-upload="beforeAvatarUpload">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2M</div>
                    </el-upload>
                </div>

                <div class="select-imageBox">
                    <div class="select-imageList">
                        <div class="select-imageItem" v-for="(item, index) in imageData" :key="index"
                         :class="{selectActive:imageIndex == index}" @click="changeImage(index, item.media_id, item.url)">
                            <img :src="item.url" />

                            <div class="mask-item" v-if="imageIndex == index">
                                <div class="el-icon-success"></div>
                            </div>
                        </div>
                    </div>

                    <div class="paging-item">
                        <el-pagination background @current-change="handleCurrentChange"
                         layout="total, prev, pager, next" :total="signalTotal">
                        </el-pagination>
                    </div>
                </div>
                
                <div class="material-btn">
                    <el-button type="primary" @click="confirmImage">确 定</el-button>
                    <el-button @click="showSelectImage = false">取 消</el-button>
                </div>
            </div>
        </el-dialog>


    </div>
</template>

<script>
    import global from '@/assets/js/global';
    import axios from 'axios';
    import VueUeditorWrap from 'vue-ueditor-wrap';

    export default {
        components: {
            VueUeditorWrap
        },
        data() {
            return {
                appid: this.$route.query.appid,
                nickName: this.$route.query.nickName,
                headImg: this.$route.query.headImg,
                showMaterial: false,
                librayIndex: -1,
                ruleName: '', // 规则名称

                editorConfig: {
                    UEDITOR_HOME_URL: '/UEditor/',
                    serverUrl: '',
                    // 初始容器高度
                    initialFrameHeight: 200,
                    // 初始容器宽度
                    initialFrameWidth: '100%',
                    toolbars: [],
                    elementPathEnabled : false,
                    // 关闭字数统计
	                wordCount:false,
                    enableAutoSave: false
                },

                contData: [
                    {texts: '文本'},
                    {texts: '图片'},
                    {texts: '小程序卡片'},
                    // {texts: '图文'}
                ],
                contIndex: 0,
                tatVal: 0,
                replyText: '',
                isChange: true,
                
                class: 'add-img',
                editorOption: '',
                page: 1,
                maxPage: 10,
                phoneEditor: '',
                showLink: false, // 链接
                nicknameText: '', // 
                showApplet: false, // 小程序链接
                classNickname: 'nickname',
                contenteditableFalse: false,
                linkText: '', // 链接文本
                showApplet: false,
                linkAddress: '', // 链接地址
                dispText: '', // 小程序链接显示文本
                showKey: false, // 回复关键词
                KeyText: '', // 回复关键词
                KeyName: '', // 回复关键词

                pagesText: '',
                title: [],
                ue: '',
                ueText: '',

                titleCard: '', // 小程序卡片
                cardAppid: '', // 小程序appid
                appletsPath: '', // 小程序路径
                thumbMediaUrl: '',
                thumbMediaId: '', // 小程序封面图
                showSelectImage: false, // 图片库弹窗
                pageSize: 0,
                signalTotal: 0,
                imageData: [],
                imageIndex: -1,
                imageUrl: '',
                imageId: '',
                param: {
                    appid: this.$route.query.appid
                },
                action: global.wechatURL + 'third/upload_file',
                newsItemData: [],
                newsPageSize: '',
                newsTotal: '',
                newsIndex: -1,
                newsUrl: '',
                newsTitle: '',
                mediaId: '',
                key: [],
                cardImageUrl: '',
                disabled: false,
                wxOptions: [],
                pagesAppid: '',
                itemId: '',
                modified: false

            }
        },
        watch: {
            value(e) {
                if (this.isChange) {
                    this.replyText = this.value;
                }
                if (!this.value) {
                    this.$el.innerHTML = '';
                }
            }
        },
        mounted() {
            window.onbeforeunload = e => {
                if (!this.modified) {
                    return;
                }
                // 通知浏览器不要执行与事件关联的默认动作
                e.preventDefault();
                // Chrome 需要 returnValue 被设置成空字符串
                e.returnValue = '';
            };
        },
        beforeRouteLeave(to, from, next) {
            if (!this.modified) {
                next();
                return;
            }
            this.$confirm('当前页面数据未保存，确定要离开？', '提示', { type: 'warning' })
            .then(() => {
                next();
            })
            .catch(() => {
                next(false);
            });
        },
        created() {
            // 素材列表
            this.getMaterial();
            // 关联的小程序appid列表
            this.getWxamplink();
            var keyItem = this.$route.query.item;
            // console.log(keyItem)
            if(keyItem) {
                this.ruleName = keyItem.name;
                this.itemId = keyItem.id;
                if(keyItem.reply_info[0].reply_type == 1) {
                    this.contIndex = 0;
                    this.ueText = keyItem.reply_info[0].text;
                } else if(keyItem.reply_info[0].reply_type == 2) {
                    this.contIndex = 1;
                    this.imageUrl = keyItem.reply_info[0].image_url;
                    this.imageId = keyItem.reply_info[0].image_id;
                } else if(keyItem.reply_info[0].reply_type == 3) {
                    this.contIndex = 2;
                    this.titleCard = keyItem.reply_info[0].title;
                    this.cardAppid = keyItem.reply_info[0].mini_appid;
                    this.appletsPath = keyItem.reply_info[0].pagepath;
                    this.thumbMediaUrl = keyItem.reply_info[0].url;
                    this.thumbMediaId = keyItem.reply_info[0].thumb_media_id;
                }
            }
            
        },
        methods: {
            destroyed() {
                // 取消对事件的监听
                window.onbeforeunload = null;
            },
            chooseKey(item, index) {
                item.keyIndex = index;
            },
            chooseCont(index) {
                var contText = this.ue.getContent();

                if(index == 1 && contText !== '' || index == 2 && contText !== '' || index == 3 && contText !== '') {
                    this.$confirm('切换消息类型后当前编辑好的内容将会自动清空?', '温馨提示', {
                        confirmButtonText: '继续',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.contIndex = index;
                        this.ue.execCommand('cleardoc');
                    }).catch(() => {
                        console.log('取消删除');
                    });
                } else if(index == 0 && this.imageUrl !== '' || index == 2 && this.imageUrl !== '' || index == 3 && this.imageUrl !== '') {
                    this.$confirm('切换消息类型后当前编辑好的内容将会自动清空?', '温馨提示', {
                        confirmButtonText: '继续',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.contIndex = index;
                        this.imageUrl = '';
                        this.imageIndex = -1;
                    }).catch(() => {
                        console.log('取消删除');
                    });
                } else if(index == 0 && (this.cardAppid !== '' || this.titleCard !== '' || this.appletsPath !== '' || this.thumbMediaId !== '') || index == 1 && (this.cardAppid !== '' || this.titleCard !== '' || this.appletsPath !== '' || this.thumbMediaId !== '') || index == 3 && (this.cardAppid !== '' || this.titleCard !== '' || this.appletsPath !== '' || this.thumbMediaId !== '')) {
                    this.$confirm('切换消息类型后当前编辑好的内容将会自动清空?', '温馨提示', {
                        confirmButtonText: '继续',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.contIndex = index;
                        this.cardAppid = '';
                        this.titleCard = '';
                        this.appletsPath = '';
                        this.thumbMediaId = '';
                        this.thumbMediaUrl = '';
                        this.imageIndex = -1;
                    }).catch(() => {
                        console.log('取消删除');
                    });
                } else if(index == 0 && this.newsUrl !== '' || index == 1 && this.newsUrl !== '' || index == 2 && this.newsUrl !== '') {
                    this.$confirm('切换消息类型后当前编辑好的内容将会自动清空?', '温馨提示', {
                        confirmButtonText: '继续',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.contIndex = index;
                        this.newsUrl = '';
                        this.newsTitle = '';
                    }).catch(() => {
                        console.log('取消删除');
                    });
                } else {
                    this.contIndex = index;
                    if(index == this.contIndex) return ;
                }

            },

            ready(editorInstance) {
                this.ue = editorInstance;
            },

            // 昵称添加 \u2002 \u200b  contenteditable='${that.contenteditableFalse}'
            nickname(e) {
                var that = this;
                var nicknameHtml = `\u200b<a class='${that.classNickname}' contenteditable='${that.contenteditableFalse}'>关注者昵称</a>\u200b`;
                that.ue.execCommand('inserthtml', nicknameHtml);
            },
            
            // 超链接
            hyperlink() {
                this.linkText = '';
                this.linkAddress = '';
                this.showLink = true;
            },
            confirmLink() {
                var that = this;
                let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
                if(that.linkText == '') {
                    that.$message({
                        message: '请输入链接显示文本',
                        type: 'warning'
                    })
                    return false
                } else if(that.linkAddress == '') {
                    that.$message({
                        message: '请输入要插入的链接地址',
                        type: 'warning'
                    })
                    return false
                } else if(!reg.test(that.linkAddress)) {
                    that.$message({
                        message: '请输入正确格式的链接地址',
                        type: 'warning'
                    })
                    return false
                }
                let link = `\u200b<a class='linkAdd' contenteditable='${that.contenteditableFalse}' href='${ that.linkAddress }' onclick='return false;'>${that.linkText}</a>\u200b`;
                that.ue.execCommand('inserthtml', link);
                that.showLink = false;
            },
            // 小程序链接
            hyperApplet() {
                this.dispText = '';
                this.pagesText = '';
                this.showApplet = true;
            },
            confirmApplet() {
                let that = this;
                if(that.dispText == '') {
                    that.$message({
                        message: '请输入链接显示文本',
                        type: 'warning'
                    })
                    return false
                } else if(that.pagesText == '') {
                    that.$message({
                        message: '请输入小程序页面地址',
                        type: 'warning'
                    })
                    return false
                }
                let linkApplet = `\u200b<a class='linkAdd linkApplet' contenteditable='${that.contenteditableFalse}' href="${that.pagesText}" data-miniprogram-appid="${that.pagesAppid}" data-miniprogram-path="${that.pagesText}" onclick='return false;'>${that.dispText}</a>\u200b`;
                that.ue.execCommand('inserthtml', linkApplet);
                that.showApplet = false;
            },
            // 回复关键词
            hyperKey() {
                this.KeyText = '';
                this.KeyName = '';
                this.showKey = true;
            },
            confirmKey() {
                let that = this;
                if(that.KeyName == '') { 
                    that.$message({
                        message: '请输入回复关键词',
                        type: 'warning'
                    })
                    return false
                }
                let keyValue = `\u200b<a class='linkAdd key' contenteditable='${that.contenteditableFalse}' href="weixin://bizmsgmenu?msgmenucontent=${that.KeyName}&msgmenuid=0" onclick="return false;">${that.KeyText ? that.KeyText : that.KeyName}</a>\u200b`;
                that.ue.execCommand('inserthtml', keyValue);
                that.showKey = false;
            },

            // 选择图片
            selectImage() {
                this.showSelectImage = true;
                this.getMaterialImage();
            },

            // 添加表情
            ClickEmoticon(emoticonNo) {
                var that = this;
                let Img = `<img class='${that.class}' src='${ that.fUrl + emoticonNo + '.png' }' />`;
                let ImgHtml = `<img class='${that.class}' src='${ that.fUrl + emoticonNo + '.png' }' />`;
                that.ue.execCommand('inserthtml', Img);
            },
            // 保存
            save() {
                var that= this;
                if(that.ruleName == '') {
                    that.$message({
                        message: '请输入规则名称',
                        type: 'warning'
                    })
                    return false
                }
                
                if(that.contIndex == 0) {
                    var contText = that.ue.getContent();
                    
                    if(contText == '') {
                        that.$message({
                            message: '请输入回复文本',
                            type: 'warning'
                        })
                        return false
                    }

                    // console.log(contText, 'contText')
                    
                    var htmlReg = new RegExp('<p>(.+?)</p>'); // 匹配p标签里的内容
                    var htmlResult = contText.match(htmlReg); // p标签里的内容
                    if (htmlResult.length > 0) {
                        var contHtmlResult = htmlResult[1];
                        // console.log(contHtmlResult, 'p-html');
                    }

                    var nameHtml = '<a class="nickname" contenteditable="false">关注者昵称</a>';
                    const nameReg = new RegExp(nameHtml, 'g');
                    // console.log(nameReg, 'nameReg')
                    var nameHtmlText = contHtmlResult.replace(nameReg, '$NICKNAME$');
                    // console.log(nameHtmlText, '关注者昵称');

                    var linkRegHtml = `<a class="linkAdd" contenteditable="${that.contenteditableFalse}" href="${ that.linkAddress }" onclick="return false;">${that.linkText}</a>`;
                    var linkRegHtmlText = `<a contenteditable="${that.contenteditableFalse}" href="${ that.linkAddress }" onclick="return false;">${that.linkText}</a>`;
                    const linkExp = new RegExp(linkRegHtml, 'g');
                    var linkCont = nameHtmlText.replace(linkExp, linkRegHtmlText);
                    // console.log(linkCont, '超链接');

                    var replaceLinkApplet = `<a class="linkAdd linkApplet" contenteditable="${that.contenteditableFalse}" href="${that.pagesText}" data-miniprogram-appid="${that.pagesAppid}" data-miniprogram-path="${that.pagesText}" onclick="return false;">${that.dispText}</a>`;
                    var replaceAppletHtml = `<a contenteditable="${that.contenteditableFalse}" href="${that.pagesText}" data-miniprogram-appid="${that.pagesAppid}" data-miniprogram-path="${that.pagesText}" onclick="return false;">${that.dispText}</a>`;
                    const appletLinkExp = new RegExp(replaceLinkApplet, 'g');
                    var appletLinkCont = linkCont.replace(appletLinkExp, replaceAppletHtml);
                    // console.log(appletLinkCont, '小程序链接');
                    if(that.itemId == '') {
                        that.attentionToReply(appletLinkCont);
                    } else {
                        that.modify(appletLinkCont);
                    }
                } else {
                    if(that.itemId == '') {
                        that.attentionToReply();
                    } else {
                        that.modify();
                    }
                }
            },
            // 取消
            cancel() {
                this.$router.go(-1);
            },
            

            // 新增关注回复
            attentionToReply(texts) {
                let that = this;
                that.disabled = true;
                
                let config_list = [];
                if(that.contIndex == 0) {
                    config_list.push({
                        reply_type: 1,
                        text: texts
                    })
                } else if(that.contIndex == 1) {
                    if(that.imageUrl == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请添加图片!',
                            type: 'warning'
                        })
                        return false
                    }
                    config_list.push({
                        reply_type: 2,
                        image_id: that.imageId,
                        image_url: that.imageUrl
                    })
                } else if(that.contIndex == 2) {
                    if(that.titleCard == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请填写卡片标题!',
                            type: 'warning'
                        })
                        return false
                    } else if(that.cardAppid == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请填写小程序apppid!',
                            type: 'warning'
                        })
                        return false
                    } else if(that.appletsPath == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请填写小程序路径!',
                            type: 'warning'
                        })
                        return false
                    } else if(that.thumbMediaId == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请选择封面图!',
                            type: 'warning'
                        })
                        return false
                    }
                    config_list.push({
                        reply_type: 3,
                        mini_appid: that.cardAppid,
                        title: that.titleCard,
                        pagepath: that.appletsPath,
                        thumb_media_id: that.thumbMediaId,
                        url: that.thumbMediaUrl
                    })
                } else if(that.contIndex == 3) {
                    if(that.mediaId == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请选择图文!',
                            type: 'warning'
                        })
                        return false
                    }
                    config_list.push({
                        reply_type: 4,
                        text: that.mediaId
                    })
                }
                
                axios.post(global.wechatURL + "third/attention_to_reply", {
                    authorizer_appid: that.appid,
                    name: that.ruleName,
                    config_list
                }).then(res => {
                    if(res.data.status == 200) {
                        that.$message({
                            message: '添加成功!',
                            type: 'warning'
                        })
                        that.$router.go(-1);
                    } else {
                        this.$message({ showClose: true, message: res.data.message, type: 'error'})
                    }
                });
            },
            // 修改关注回复
            modify(texts) {
                let that = this;
                that.disabled = true;
                
                let config_list = [];
                if(that.contIndex == 0) {
                    config_list.push({
                        reply_type: 1,
                        text: texts
                    })
                } else if(that.contIndex == 1) {
                    if(that.imageUrl == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请添加图片!',
                            type: 'warning'
                        })
                        return false
                    }
                    config_list.push({
                        reply_type: 2,
                        image_id: that.imageId,
                        image_url: that.imageUrl
                    })
                } else if(that.contIndex == 2) {
                    if(that.titleCard == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请填写卡片标题!',
                            type: 'warning'
                        })
                        return false
                    } else if(that.cardAppid == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请填写小程序apppid!',
                            type: 'warning'
                        })
                        return false
                    } else if(that.appletsPath == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请填写小程序路径!',
                            type: 'warning'
                        })
                        return false
                    } else if(that.thumbMediaId == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请选择封面图!',
                            type: 'warning'
                        })
                        return false
                    }
                    config_list.push({
                        reply_type: 3,
                        mini_appid: that.cardAppid,
                        title: that.titleCard,
                        pagepath: that.appletsPath,
                        thumb_media_id: that.thumbMediaId,
                        url: that.thumbMediaUrl
                    })
                } else if(that.contIndex == 3) {
                    if(that.mediaId == '') {
                        that.disabled = false;
                        that.$message({
                            message: '请选择图文!',
                            type: 'warning'
                        })
                        return false
                    }
                    config_list.push({
                        reply_type: 4,
                        text: that.mediaId
                    })
                }
                
                axios.put(global.wechatURL + "third/attention_to_reply", {
                    authorizer_appid: that.appid,
                    id: that.itemId,
                    name: that.ruleName,
                    config_list
                }).then(res => {
                    if(res.data.status == 200) {
                        that.$message({
                            message: '修改成功!',
                            type: 'warning'
                        })
                        that.$router.go(-1);
                    } else {
                        this.$message({ showClose: true, message: res.data.message, type: 'error'})
                    }
                });
            },

            // 图文弹框
            showMat() {
                this.showMaterial = true;
            },
            // 素材列表
            getMaterial() {
                axios.get(global.wechatURL + "third/get_material", {
                    params: {
                        appid: this.appid,
                        type: 'news',
                        offset: this.page,
                        count: this.maxPage
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var Lists = res.data.data.item;
                        Lists.forEach((item) => {
                            // console.log(item.content.news_item, 'item')
                            item.content.news_item.forEach((items) => {
                                this.newsItemData.push(items);
                            })
                        })
                        this.newsPageSize = res.data.data.item_count;
                        this.newsTotal = res.data.data.total_count;
                        
                    } else {
                        this.$message({ showClose: true, message: res.data.message, type: 'error'})
                    }
                });
            },
            // 关联的小程序appid列表
            getWxamplink() {
                axios.get(global.wechatURL + "third/wxamplinkget", {
                    params: {
                        appid: this.appid
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var data = res.data.data;
                        this.wxOptions = data;
                    } else {
                        this.$message({ showClose: true, message: res.data.message, type: 'error'})
                    }
                });
            },
            selectMarket(e) {
                this.cardAppid = e;
            },
            selectAppid(e) {
                this.pagesAppid = e;
                // console.log(this.pagesAppid)
            },
            newsHandleCurrentChange(val) {
                this.page = val;
                this.newsItemData = [];
                this.newsIndex = -1;
                this.newsUrl = '';
                this.newsTitle = '';
                this.mediaId = '';
                this.getMaterial();
            },
            chooseLibrary(index, thumb_media_id, thumb_url, title) {
                if(index !== this.newsIndex) {
					this.newsIndex = index;
                    this.mediaId = thumb_media_id;
                    this.newsUrl = thumb_url;
                    this.newsTitle = title;
				} else {
					this.newsIndex = -1;
                    this.newsUrl = '';
                    this.newsTitle = '';
                    this.mediaId = '';
				}
            },
            confirmNews() {
                let that = this;
                if(that.newsUrl == '') {
                    that.$message({
                        message: '请选择图文素材！',
                        type: 'warning'
                    })
                    return false
                }
                that.showMaterial = false;
            },
            // 获取图片列表
            getMaterialImage() {
                axios.get(global.wechatURL + "third/get_material", {
                    params: {
                        appid: this.appid,
                        type: 'image',
                        offset: this.page,
                        count: this.maxPage
                    }
                }).then(res => {
                    if(res.data.status == 200) {
                        var List = res.data.data.item;
                        this.imageData = List;
                        this.pageSize = res.data.data.item_count;
                        this.signalTotal = res.data.data.total_count;
                    } else {
                        this.$message({ showClose: true, message: res.data.message, type: 'error'})
                    }
                });
            },
            
            handleCurrentChange(val) {
                this.page = val;
                this.imageData = [];
                this.imageIndex = -1;
                this.thumbMediaId = '';
                this.imageUrl = '';
                this.imageId = '';
                this.getMaterialImage();
            },
            changeImage(index, mediaId, url) {
                if(index !== this.imageIndex) {
					this.imageIndex = index;
                    if(this.contIndex == 1) {
                        this.imageUrl = url;
                        this.imageId = mediaId;
                    } else if(this.contIndex == 2) {
                        this.thumbMediaId = mediaId;
                        this.thumbMediaUrl = url;
                    }
				} else {
					this.imageIndex = -1;
                    this.imageUrl = '';
                    this.imageId = '';
                    this.thumbMediaId = '';
				}
            },
            confirmImage() {
                let that = this;
                if(that.imageUrl == '' && that.contIndex == 1) {
                    that.$message({
                        message: '请选择图片素材！',
                        type: 'warning'
                    })
                    return false
                } else if(that.thumbMediaUrl == '' && that.contIndex == 2) {
                    that.$message({
                        message: '请选择图片封面！',
                        type: 'warning'
                    })
                    return false
                }
                that.showSelectImage = false;
            },

            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type == 'image/jpeg' || file.type == 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG/PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            onSuccess() {
                this.getMaterialImage();
                this.clearFiles();
            },
            clearFiles() {
                this.$refs.upload.clearFiles();
            }


        }
    }
</script>

<style lang="less" scoped>
    img {
        display: block;
        width: 100%;
        height: 100%;
    }
    .content {
        padding: 0 25px;
    }

    .keywords-wrap {
        margin-top: 25px;
        .keywords-box {
            
            .keywords-item {
                display: flex;
                align-items: baseline;
                .keywords-name {
                    margin-right: 20px;
                    width: 100px;
                    font-size: 16px;
                    color: #252530;
                    text-align: right;
                }
                .keywords-inp {
                    margin-bottom: 20px;
                    width: 385px;
                }
            }
        }
        .choose-box {
            display: flex;
            align-items: center;
            .choose-item {
                display: flex;
                align-items: center;
                cursor: pointer;
                &:nth-child(1) {
                    margin-right: 20px;
                }
                .choose-icon {
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                }
                .choose-text {
                    font-size: 14px;
                    font-weight: bold;
                    color: #252530;
                }
            }
        }
        .reply-content {
            display: flex;
            align-items: flex-start;
            .reply-name {
                margin-right: 20px;
                width: 100px;
                font-size: 16px;
                color: #252530;
                text-align: right;
            }
            .reply-right {

                .reply-item {
                    margin-right: 20px;
                }
            }
        }
    }

    .tab-cont {
        margin-top: 20px;
    }
    .nvarchar-wrap {
        width: 650px;
        border: 1px solid #BABEC7;
        .nvarchar-top {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #BABEC7;
            .-top-title {
                width: 80px;
                background: #F6F7FB;
                border-right: 1px solid #BABEC7;
                font-size: 14px;
                text-align: center;
                line-height: 50px;
                color: #252530;
            }
            .insert-type {
                display: flex;
                align-items: center;
                .nickname-item {
                    margin-left: 20px;
                    padding: 0 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                    background: #FFFFFF;
                    border: 1px solid #BABEC7;
                    border-radius: 8px;
                    cursor: pointer;
                    .nickname-icon {
                        margin-right: 6px;
                        width: 18px;
                        height: 18px;
                        background: #FF6056;
                        border-radius: 50%;
                    }
                    .hyperlink-icon {
                        background: #5096FF;
                    }
                    .link-icon {
                        background: #18B528;
                    }
                    .key-icon {
                        background: #FE8D07;
                    }
                    .nickname-text {
                        font-size: 14px;
                        color: #252530;
                    }
                }
            }
        }
        .reply-text {
            position: relative;
            width: 100%;
            -height: 130px;
            -overflow-y: scroll;
            /deep/ .quill-editor {
                height: 188px;
            }
            /deep/ .ql-toolbar.ql-snow {
                display: none;
            }
            .reply-cont {
                padding: 10px;
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                resize: none;
                box-sizing: border-box;
            }
        }
        .reply-bot {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .face-btn {
                position: relative;
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
            .words-text {
                font-size: 14px;
                color: #828494;
            }
        }
    }
    textarea::-webkit-input-placeholder {
        font-size: 14px;
        color: #828494;
    }
    textarea:-moz-placeholder {
        font-size: 14px;
        color: #828494;
    }
    textarea::-moz-placeholder {
        font-size: 14px;
        color: #828494;
    }
    textarea::-ms-input-placeholder {
        font-size: 14px;
        color: #828494;
    }
    
    .face-list {
        display: flex;
        flex-wrap: wrap;
        .face-item {
            width: 24px;
            height: 24px;
            text-align: center;
            line-height: 35px;
            cursor: pointer;
            img {
                display: inline-block;
                width: 20px;
                height: 20px;
            }
        }
    }

    .new-b-wrap {
        margin: 35px 0 35px 120px;
        display: flex;
        align-items: center;
        .new-btn {
            margin-right: 15px;
            width: 100px;
            height: 45px;
            background: #FFFFFF;
            border: 1px solid #3E3F54;
            border-radius: 6px;
            font-size: 14px;
            letter-spacing: 1px;
            color: #3E3F54;
            text-align: center;
            line-height: 45px;
            cursor: pointer;
        }
        .new-text {
            font-size: 14px;
            color: #FF6056;
        }
    }

    .image-text-btn {
        padding: 7px 0 0;
        width: 185px;
        height: 85px;
        border: 1px solid #BABEC7;
        box-sizing: border-box;
        cursor: pointer;
        .el-icon-plus {
            display: flex;
            justify-content: center;
            font-size: 42px;
            color: #a3a4b3;
        }
        .add-text {
            font-size: 14px;
            color: #a3a4b3;
            text-align: center;
            line-height: 30px;
        }
    }


    .material-box {
        display: flex;
        flex-wrap: wrap;
        .material-item {
            margin: 0 8px 10px 0;
            width: 110px;
            cursor: pointer;
            &:nth-child(6n) {
                margin-right: 0;
            }
            .material-img {
                position: relative;
                width: 100%;
                height: 110px;
                background: #F6F7FB;
                border: 1px solid transparent;
            }
            .activeImg {
                border: 1px solid #07c160;
            }
            .material-text {
                font-size: 14px;
                color: #252530;
                line-height: 30px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
        }
    }
    .material-item:hover .material-img {
        border: 1px solid #8cd68c;
    }
    .material-btn {
        margin-top: 25px;
        display: flex;
        justify-content: center;
    }

    .contenteditable-box {
        padding: 10px;
        width: 100%;
        height: 130px;
        overflow-y: scroll;
        word-break: break-all;
        outline: none;
        user-select: text;
        white-space: pre-wrap;
        text-align: left;
        box-sizing: border-box;
    }
    .contenteditable-box[contenteditable='true'] {
        user-modify: read-write-plaintext-only;
    }
    .contenteditable-box[contenteditable='true']:empty:before {
        content: attr(placeholder);
        display: block;
        color: #ccc;
    }
    .reply-text {
        position: relative;
    }
    
    .link-wrap {

        .link-inpItem {
            margin: 20px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .link-inpText {
                width: 100px;
                font-size: 14px;
                color: #252530;
                text-align: center;
            }
            .link-inp {
                width: 255px;
                /deep/ .el-select {
                    display: block;
                }
            }
        }
        .btns-item {
            margin: 25px 0 0;
            display: flex;
            justify-content: center;
        }
    }
    
    .reply-text /deep/ .edui-default .edui-editor {
        border: none;
    }

    .picture-item {
        display: flex;
        align-items: flex-start;
        .picture-name {
            width: 100px;
            font-size: 14px;
            color: #252530;
        }
    }
    .picture-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 165px;
        height: 165px;
        border: 1px solid #e5e5e5;
        cursor: pointer;
        .el-icon-btn {
            display: flex;
            justify-content: center;
            .el-icon-plus {
                font-size: 36px;
                color: #a3a4b3;
            }
        }
        .picture-text {
            font-size: 14px;
            color: #a3a4b3;
            text-align: center;
            line-height: 35px;
        }
    }
    .reselect-item {
        display: flex;
        align-items: flex-end;
        .reselect-btn {
            margin-left: 10px;
            font-size: 14px;
            color: #5096FF;
            line-height: 25px;
            cursor: pointer;
        }
    }

    .appletCard-box {

        .appletCard-inpItem {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            .inpItem-name {
                margin-right: 20px;
                width: 100px;
                font-size: 14px;
                color: #252530;
                text-align: right;
            }
            .inpItem {
                width: 375px;
                /deep/ .el-select {
                    display: block;
                }
            }
            a {
                margin-left: 10px;
                font-size: 14px;
                color: #FF6056;
                text-decoration-line: none;
            }
        }
    }

    .btnsItem {
        margin: 155px 0 0 375px;
    }

    .upload-item {
        margin-bottom: 25px;
    }
    .select-imageList {
        display: flex;
        flex-wrap: wrap;
        min-height: 375px;
        .select-imageItem {
            position: relative;
            margin: 0 15px 15px 0;
            width: 165px;
            height: 165px;
            cursor: pointer;
        }
        .selectActive {

            .mask-item {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 35;
                display: flex;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .5);
                .el-icon-success {
                    font-size: 32px;
                    color: #FE8D07;
                }
            }
        }
    }
    .paging-item {
        margin: 25px 0 0;
        display: flex;
        justify-content: center;
    }

    .account-img {
        display: flex;
        align-items: center;
        width: 385px;
        height: 105px;
        .card-img {
            margin-right: 35px;
        }
        .avatar {
            width: 165px;
            height: 105px;
        }
    }

</style>
